export class AppSettings {

  public static IS_IE_OR_EDGE = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

  //------------------------------------------------------------------
  // Live  
  // public static API_ENDPOINT ='https://api.insiderscorp.com/api/v1'; 
  // by pass cross domain issue with proxy in IE and edge browsers
  // public static API_ENDPOINT = AppSettings.IS_IE_OR_EDGE?  'https://clients.insiderscorp.com/api/v1': 'https://api.insiderscorp.com/api/v1';  

  //------------------------------------------------------------------  
  // Local
  // public static API_ENDPOINT = 'http://10.0.0.18:3000/api/v1';
  //------------------------------------------------------------------
  // Staging
  public static API_ENDPOINT = 'https://api.insiderscorp.com/api/v1';
  //  public static API_ENDPOINT ='http://35.171.76.52:4400/api/v1';   
  //------------------------------------------------------------------
  public static APP_START_YEAR = 2018;
  public static DEFAULT_PAGER_COUNT = 50;
  public static DEFAULT_QUERY_LIMIT = 100000;
  //------------------------------------------------------------------
  // Live
  // public static IMAGE_BASE_URL = 'https://api.insiderscorp.com/files/';
  // public static IMAGE_BASE_URL = AppSettings.IS_IE_OR_EDGE?  'https://clients.insiderscorp.com/files/': 'https://api.insiderscorp.com/files/';  
  //------------------------------------------------------------------
  // Local
  // public static IMAGE_BASE_URL = 'http://10.0.0.18:3000/files/';
  public static IMAGE_BASE_URL = 'https://api.insiderscorp.com/files/';
  public static SCREENSHOT_BASE_URL = 'https://d1br57uxtwv17b.cloudfront.net/';
  //------------------------------------------------------------------
  // Staging
  //  public static IMAGE_BASE_URL = 'http://35.171.76.52:4400/files/';
  //------------------------------------------------------------------

  public static Session_Expire_Message = "Session Expired";
  public static Session_Expire_Description = "Kindly login";
}
