import { Component, OnInit, ElementRef, AfterViewChecked, ViewChild } from '@angular/core';
import { trigger, state, style, animate, transition } from '@angular/animations';
// import { D3Service, D3, Selection } from 'd3-ng2-service';
import * as D3 from 'd3';
import * as topojson from 'topojson';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import * as moment from 'moment';
import { LocalStorageService } from 'ngx-webstorage';
import { BrandService } from '../../services/brand/brand.service';
import { Http } from '@angular/http';
import { LoaderService } from '../../services/loader/loader.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { AppSettings } from '../../settings.global';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers : [TitleCasePipe],
  animations: [
    trigger("reportedLinkTrigger", [
      // define states
      state("on", style({ opacity: 0.5 })),
      state("off", style({ opacity: 1 }))
    ]),
    trigger("successRateTrigger", [
      // define states
      state("on", style({ opacity: 0.5 })),
      state("off", style({ opacity: 1 }))
    ]),
    trigger("disabledLinkTrigger", [
      // define states
      state("on", style({ opacity: 0.5 })),
      state("off", style({ opacity: 1 }))
    ])
  ]
  
})
// export class TabGroupBasicExample {}
export class DashboardComponent implements OnInit {

  @ViewChild('mpSuccessRate') mpSuccessRate: ElementRef;
  @ViewChild('smSuccessRate') smSuccessRate: ElementRef;
  @ViewChild('swSuccessRate') swSuccessRate: ElementRef;
  @ViewChild('regionMap') regionMap: ElementRef;

  private parentNativeElement: any;
  public rangeMonthTitle: any = '1st of ' + moment().format("MMMM");
  public selectedDateRangeLabel : any;

  successRateData: {
    'total_reported_links': {
      'total_count': number;
      'social_media': number;
      'market_place': number;
      'standalone': number;
    },
    'total_disabled_links': {
      'total_count': number;
      'social_media': number;
      'market_place': number;
      'standalone': number;
    }
  };

  mpData: {
    data: {'sum': number, 'name': string}[] | any[];
    successRate: number;
  };

  mpDatavalues: number[];

  smData: {
    data: {'sum': number, 'name': string}[] | any[];
    successRate: number;
  };

  smDatavalues: number[];

  swData: {
    data: {'sum': number, 'name': string}[] | any[];
    successRate: number;
  };

  top1: any;

  swDatavalues: number[];

  mpColors = ['#4d4d00','#808000','#b3b300','#cccc00','#d7b70f','#f0d028'];

  // smColors = ['#303030','#404040','#505050','#606060','#696969','#808080'];
  smColors = ['#c75ac7','#ee82ee','#8d5ba7','#ee82d3','#a682ee','#745ba7'];
  

  swColors = ['#003d66','#005c99','#007acc','#0099ff','#33adff','#80ccff'];

  // tslint:disable-next-line:max-line-length
  mapData: {country: string, code: string, reportedLink: string, source_counts: { market_place: number, social_media: number, stand_alone: number, total: number}}[];

  public reportedLinks: {
    total: number,
    sm: number,
    mp: number,
    sw: number,
    percentageSm: number,
    percentageMp: number,
    percentageSw: number,
  };

  public disabledLinks: {
    total: number,
    sm: number,
    mp: number,
    sw: number,
    percentageSm: number,
    percentageMp: number,
    percentageSw: number,
  };

  regions: {}[];

  countries: {}[];

  daterange: any = {
    start: moment().startOf('year').format('YYYY-MM-DD'),
    end : moment().format('YYYY-MM-DD')
  };

  searchBase: {
    // brandId: number,
    brandId: string,
    reportedLink: boolean,
    region: string | number,
    fromDate: string,
    toDate: string
  };

  public datepickerOptions: any = {
    // startDate: moment().startOf('year'),
    // endDate: moment(),
    minDate: '01/01/2000',
    maxDate: moment(),
    showDropdowns: !0,
    showWeekNumbers: !0,
    timePicker: !1,
    timePickerIncrement: 1,
    timePicker12Hour: !0,
    ranges: {
      'Last 30 Days': [moment().subtract(29, 'days'), moment()],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment()],
      'Since January 1st': [moment().startOf('year'), moment()],
      // '1st of the Month' :[moment().startOf('month') , moment()]
    },
    opens: 'left',
    buttonClasses: ['btn btn-default'],
    applyClass: 'btn-small btn-primary',
    cancelClass: 'btn-small',
    format: 'MM/DD/YYYY',
    separator: ' to ',
    locale: {
      applyLabel: 'Submit',
      cancelLabel: 'Clear',
      fromLabel: 'From',
      toLabel: 'To',
      customRangeLabel: 'Time Range',
      daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      // tslint:disable-next-line:max-line-length
      monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      firstDay: 1,
      // applyOnMenuSelect: true
    }
    
  };
  public datePickerSelected:any;


  public successRate: any;
  public reportedStatus: any;
  public successStatus: any;
  public disabledStatus: any;
  public lsData: any;
  public mpArray: any;
  public smArray: any;
  public swArray: any;
  public top: any;
  public left: any;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public reported:any;
  public disabled:any;
  public brandIdList: any;
  public routeLinks: any;
  public activeLinkIndex: any;
  public active_brand_id_data: any;
  public all_brand_ids_data: any;
  public selected_brand_list_data: any;

  public  mp_selected_brand_list_data :any;
  public  sm_selected_brand_list_data :any;
  public  sa_selected_brand_list_data :any;
  public  rb_selected_brand_list_data :any;
  public  link_selected_brand_list_data :any;

  // public selectedDateRangeLabel : any;

  constructor(
    private dashboardService: DashboardService,
    private element: ElementRef,
    // private d3Service: D3Service,
    private lsService: LocalStorageService,
    private brandService: BrandService,
    private loaderService:LoaderService,
    private authServiceObj:AuthService,
    private toastr: ToastrService,
    private http: Http,
    private titleCasePipe:TitleCasePipe
  ) {
    
    // this.d3 = d3Service.getD3(); // <-- obtain the d3 object from the D3 Service
    //selectedBrandsDetails = [];   

    // var selectedBrandsDetails = JSON.parse(this.lsService.retrieve('selectedBrands'));
    var unsortedSelectedBrandsDetails = JSON.parse(this.lsService.retrieve('selectedBrands'));  
    //Sort the list of brands selected
    var selectedBrandsDetails = unsortedSelectedBrandsDetails.sort((a, b) => (a.name > b.name) ? 1 : -1)      
    
    if(!selectedBrandsDetails || selectedBrandsDetails == null)
    {
      selectedBrandsDetails = [];      
    }
    this.parentNativeElement = element.nativeElement;
    this.successRate = 0;
    this.lsData = JSON.parse(this.lsService.retrieve('search_base'));
    this.searchBase = {
      brandId: this.lsService.retrieve('active_brand_id'),
      reportedLink: this.lsData.reportedLink,
      region: this.lsData.region,
      fromDate: this.lsData.fromDate,
      toDate: this.lsData.toDate
    };
    this.daterange.start = this.lsData.fromDate;
    this.daterange.end = this.lsData.toDate;

    this.reportedLinks = {
      total: 0,
      sm: 0,
      mp: 0,
      sw: 0,
      percentageSm: 0,
      percentageMp: 0,
      percentageSw: 0,
    };

    this.disabledLinks = {
      total: 0,
      sm: 0,
      mp: 0,
      sw: 0,
      percentageSm: 0,
      percentageMp: 0,
      percentageSw: 0,
    };

    this.mpData = {
      data: [],
      successRate: 0
    };

    this.mpDatavalues = [];
    // this.smData = {}

    this.smData = {
      data: [],
      successRate: 0
    };

    this.smDatavalues = [];

    this.swData = {
      data: [],
      successRate: 0
    };

    this.swDatavalues = [];

    this.mapData = [];

    
    this.top1 ={
      market_place : '',
      social_media : '',
      stand_alone : ''
    }
    this.mpArray = [];
    this.smArray = [];
    this.swArray = [];
    // var selectedSummaryObj = {id:0, name:'All Selectedd',spcialClass:'active-brands-tab'};
    // if(selectedBrandsDetails.length > 1)
    // {
    //   selectedBrandsDetails.unshift(selectedSummaryObj);
    // }
    // if(selectedBrandsDetails.length == 1)
    // {
    //   selectedBrandsDetails[0].spcialClass = 'active-brands-tab';
    // }
    
    // this.selected_brand_list_data = selectedBrandsDetails;   
    
    //--------------------edited-------------------
    // var selectedSummaryObj = {id:0, name:'All Selectedd',spcialClass:'active-brands-tab'};
    var mp_selectedSummaryObj = {id:0, name:'Marketplaces',spcialClass:'active-brands-tab '};
    var sm_selectedSummaryObj = {id:0, name:'Social Media',spcialClass:'active-brands-tab '};
    var sa_selectedSummaryObj = {id:0, name:'Standalone Websites',spcialClass:'active-brands-tab '};
    var rb_selectedSummaryObj = {id:0, name:'Regional Breakdown',spcialClass:'active-brands-tab '}; 
    var link_selectedSummaryObj = {id:0, name:'All',spcialClass:'active-brands-tab '};    
    console.log('****sortedSelectedBrandDetailsTEsttttttttttttt--')
    var sortedSelectedBrandDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));
    console.log('****sortedSelectedBrandDetails--Before---',sortedSelectedBrandDetails)

    // Deep clone
    var mp_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));
    var sm_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails));
    var sa_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails)); 
    var rb_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails)); 
    var link_selectedBrandsDetails = JSON.parse(JSON.stringify(selectedBrandsDetails)); 
       
    
    if(selectedBrandsDetails.length >= 1) {    
      mp_selectedBrandsDetails.unshift(mp_selectedSummaryObj);
      sm_selectedBrandsDetails.unshift(sm_selectedSummaryObj);
      sa_selectedBrandsDetails.unshift(sa_selectedSummaryObj);
      rb_selectedBrandsDetails.unshift(rb_selectedSummaryObj);
      link_selectedBrandsDetails.unshift(link_selectedSummaryObj);
    }    
      this.mp_selected_brand_list_data   = mp_selectedBrandsDetails;
      this.sm_selected_brand_list_data   = sm_selectedBrandsDetails;
      this.sa_selected_brand_list_data   = sa_selectedBrandsDetails; 
      this.rb_selected_brand_list_data   = rb_selectedBrandsDetails;
      this.link_selected_brand_list_data = link_selectedBrandsDetails;

    //-------------------Edited completed------------------------------


    // Current month option added in range options
    this.datepickerOptions.ranges[this.rangeMonthTitle]=[moment().startOf('month') , moment()]
    //console.log(this.dropdownBrand);
    this.datepickerOptions.startDate =  new Date(this.lsData.fromDate);
    this.datepickerOptions.endDate = new Date(this.lsData.toDate);         
    //console.log(this.lsData);
    //alert(1);
   
  }
  // function for scroll brands tab
  //Added By : Arif
  slideBrand(position:any, type:any)
  {
    //alert(type);
    const conent = document.querySelector('#'+type+'brndListCoverMain');
    if(position == 'right') conent.scrollLeft += 100;
    if(position == 'left') conent.scrollLeft -= 100;
    event.preventDefault();
    //alert(position);
  }
    // function to display chart for markrt places based on tabs selected
    //Added By : Arif
    viewBrandsTabData(index:any, type:string){
    var previousSelectedId = document.querySelector("."+type+"active-brands-tab").id;
    //alert(type+'selectedBrand_'+index);
    document.getElementById(previousSelectedId).classList.remove(type+"active-brands-tab","active-brands-tab");
    document.getElementById(type+'selectedBrand_'+index).classList.add(type+"active-brands-tab","active-brands-tab");
    this.loaderService.display(true);
    var searchDataList = this.searchBase;
    if(index != 0)
    {
      searchDataList.brandId = index;
    }
    else{
      searchDataList.brandId = this.lsService.retrieve('active_brand_id');
    }
    this.dashboardService.getDashboardData( searchDataList )
      .subscribe(
        (res) => {
          if(res.json().status == "success"){            
            const data = res.json();           
            
            if(type == 'mp_')
            {
              this.mpData = {
                data: data.market_place.market_place,
                successRate: data.market_place.query_success_rate
              };
              if(this.mpData.data.length >= 1){
                this.mpArray = res.json().market_place.market_place;
              }else{
                this.mpArray = null;
              }      
              const mpvalues: any[] = this.mpData.data;
              if(mpvalues.length != 0){
                this.top1.market_place = mpvalues[0].id;
              }

              this.mpDatavalues = mpvalues.map(val => val.sum);

              this.dashboardService.mpDataChanged.next(this.mpData);
            }
            if(type == 'sm_')
            {
              this.smData = {
                data: data.social_media.social_media,
                successRate: data.social_media.query_success_rate
              };

              if(this.smData.data.length >= 1){
                this.smArray = this.smData.data;
              }else{
                this.smArray = null;
              } 

              const smvalues: any[] = this.smData.data;
              if(smvalues.length != 0){
                this.top1.social_media = smvalues[0].id;
              }

              this.smDatavalues = smvalues.map(val => val.sum);
            }
            if(type == 'sa_')
            {
              this.swData = {
                data: data.standalone_website.standalone_website,
                successRate: data.standalone_website.query_success_rate
              };
              if(this.swData.data.length >= 1){
                this.swArray = this.swData.data;
              }else{
                this.swArray = null;
              }   

              const swvalues: any[] = this.swData.data;
              if(swvalues.length != 0){
                this.top1.stand_alone = swvalues[0].id;
              }      
              this.lsService. store('top1', JSON.stringify(this.top1));  

              this.swDatavalues = swvalues.map(val => val.sum);

            }

            if(type == 'rb_') {
              const retrievedMapData: any[] = data.fake_product_per_region;
              if (retrievedMapData.length !== 0 ) {
                this.mapData = retrievedMapData.map( (val) => {val.reportedLink = this.searchBase.reportedLink.toString(); return val; } );
              } else {
                this.mapData = [];
              }
              this.plotMap(this.regionMap, this.mapData);
            }
            // When this tab changes, then selected brand's details should be updated in marketplace
            // social media, stand alone and regional breakdown.
            if(type == 'link_') {
              // Updated the selected brand in tab of other graphs(type=[mp_,sm_,sa_,rb_]).
              
              // Updated the selected brand in tab of Marketplace
              var previousSelectedId = document.querySelector("."+"mp_"+"active-brands-tab").id;              
              document.getElementById(previousSelectedId).classList.remove("mp_"+"active-brands-tab","active-brands-tab");
              document.getElementById("mp_"+'selectedBrand_'+index).classList.add("mp_"+"active-brands-tab","active-brands-tab");
              
              // Updated the selected brand in tab of social media.
              previousSelectedId = document.querySelector("."+"sm_"+"active-brands-tab").id;              
              document.getElementById(previousSelectedId).classList.remove("sm_"+"active-brands-tab","active-brands-tab");
              document.getElementById("sm_"+'selectedBrand_'+index).classList.add("sm_"+"active-brands-tab","active-brands-tab");

              // Updated the selected brand in tab of stand alone.
              previousSelectedId = document.querySelector("."+"sa_"+"active-brands-tab").id;              
              document.getElementById(previousSelectedId).classList.remove("sa_"+"active-brands-tab","active-brands-tab");
              document.getElementById("sa_"+'selectedBrand_'+index).classList.add("sa_"+"active-brands-tab","active-brands-tab");

              // Updated the selected brand in tab of regional breakdown.
              previousSelectedId = document.querySelector("."+"rb_"+"active-brands-tab").id;              
              document.getElementById(previousSelectedId).classList.remove("rb_"+"active-brands-tab","active-brands-tab");
              document.getElementById("rb_"+'selectedBrand_'+index).classList.add("rb_"+"active-brands-tab","active-brands-tab");

              // Update response of selected brand in total reported links and total removed links.
              this.successRateData = data.success_rate;
              this.reportedLinks.total = this.successRateData.total_reported_links.total_count;
              this.reportedLinks.sm = this.successRateData.total_reported_links.social_media;
              this.reportedLinks.mp = this.successRateData.total_reported_links.market_place;
              this.reportedLinks.sw = this.successRateData.total_reported_links.standalone;
  
              this.reportedLinks.percentageSm = this.getPercent( this.reportedLinks.sm, this.reportedLinks.total );
              this.reportedLinks.percentageMp = this.getPercent( this.reportedLinks.mp, this.reportedLinks.total );
              this.reportedLinks.percentageSw = this.getPercent( this.reportedLinks.sw, this.reportedLinks.total );
  
              this.disabledLinks.total = this.successRateData.total_disabled_links.total_count;
              this.disabledLinks.sm = this.successRateData.total_disabled_links.social_media;
              this.disabledLinks.mp = this.successRateData.total_disabled_links.market_place;
              this.disabledLinks.sw = this.successRateData.total_disabled_links.standalone;
  
              this.disabledLinks.percentageSm = this.getPercent( this.disabledLinks.sm, this.disabledLinks.total );
              this.disabledLinks.percentageMp = this.getPercent( this.disabledLinks.mp, this.disabledLinks.total );
              this.disabledLinks.percentageSw = this.getPercent( this.disabledLinks.sw, this.disabledLinks.total );
  
              this.successRate = this.getPercent(this.disabledLinks.total, this.reportedLinks.total);
              
              // Update response of selected brand in marketplaces(type=mp_).
              this.mpData = {
                data: data.market_place.market_place,
                successRate: data.market_place.query_success_rate
              };
              if(this.mpData.data.length >= 1){
                this.mpArray = res.json().market_place.market_place;
              }else{
                this.mpArray = null;
              }      
              const mpvalues: any[] = this.mpData.data;
              if(mpvalues.length != 0){
                this.top1.market_place = mpvalues[0].id;
              }

              this.mpDatavalues = mpvalues.map(val => val.sum);

              this.dashboardService.mpDataChanged.next(this.mpData);

              // Update response of selected brand in social media(type=sm_).
              this.smData = {
                data: data.social_media.social_media,
                successRate: data.social_media.query_success_rate
              };

              if(this.smData.data.length >= 1){
                this.smArray = this.smData.data;
              }else{
                this.smArray = null;
              } 

              const smvalues: any[] = this.smData.data;
              if(smvalues.length != 0){
                this.top1.social_media = smvalues[0].id;
              }

              this.smDatavalues = smvalues.map(val => val.sum);

              // Update response of selected brand in stand alone(type=sa_).
              this.swData = {
                data: data.standalone_website.standalone_website,
                successRate: data.standalone_website.query_success_rate
              };
              if(this.swData.data.length >= 1){
                this.swArray = this.swData.data;
              }else{
                this.swArray = null;
              }   

              const swvalues: any[] = this.swData.data;
              if(swvalues.length != 0){
                this.top1.stand_alone = swvalues[0].id;
              }      
              this.lsService. store('top1', JSON.stringify(this.top1));  

              this.swDatavalues = swvalues.map(val => val.sum);

              // Update response of selected brand in regional breakdown(type=rb_).
              const retrievedMapData: any[] = data.fake_product_per_region;
              if (retrievedMapData.length !== 0 ) {
                this.mapData = retrievedMapData.map( (val) => {val.reportedLink = this.searchBase.reportedLink.toString(); return val; } );
              } else {
                this.mapData = [];
              }
              this.plotMap(this.regionMap, this.mapData);



            }

            
            
            
            this.loaderService.display(false);
          }else{
            this.loaderService.display(false);
            if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
              //this.toastr.error(this.errorDscrp,this.errorMsg);
              this.authServiceObj.logout(true);
            }
          }
          
        }
      );
      // Section to display chart ends here
    
  }
  
  

  ngOnInit() {  
    
      if(this.lsData.reportedLink == 'false'){
        this.reportedStatus = 'on';
        this.successStatus = 'on';
        this.disabledStatus = 'off';
        this.reported = false;
        this.disabled = true;
       
      }else{
        this.reportedStatus = 'off';
        this.successStatus = 'off';
        this.disabledStatus = 'on';
        this.reported = true;
        this.disabled = false;
        
      }
    // if(this.lsData.reportedLink == true){
    //   this.reportedStatus = 'off';
    //   this.successStatus = 'off';
    //   this.disabledStatus = 'on';
    //   this.reported = true;
    //   this.disabled = false;
    // }else{
    //   this.reported = false;
    //   this.disabled = true;
    //   this.reportedStatus = 'on';
    //   this.successStatus = 'on';
    //   this.disabledStatus = 'off';

    // }
    this.lsService.clear('top_market_place_sub');
    this.lsService.clear('top_social_media_sub');
    this.lsService.clear('top_social_media');
    this.lsService.clear('top_market_place');
    this.lsService.clear('top_standalone_website');
    this.lsService.clear('top_standalone_website_name');
    this.loaderService.display(true);
    this.dashboardService.getCountries()
      .subscribe(
        (res) => {
          this.loaderService.display(false);
          if(res.json().status == 'success'){
            const data = res.json();
            this.regions = data['region'];
            this.countries = data['country'];
          }else{
            if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
              //this.toastr.error(this.errorDscrp,this.errorMsg);
              this.authServiceObj.logout(true);
            }
          }          
        }
      );
      //this.loaderService.display(true);
      // this.brandService.brandChanged
      //   .subscribe(
      //     (brandId) => {
      //       this.searchBase = {
      //         brandId: brandId,
      //         reportedLink: true,
      //         region: 'all',
      //         fromDate: this.daterange.start,
      //         toDate: this.daterange.end
      //       };
      //       this.getDashboardData();
      //     }
      //   );
        this.getDashboardData(); 
        setTimeout(function(){      
        // Inner section calculation
        var coverWidth = document.getElementById('mp_brndListCoverMain').offsetWidth; 
        var contentWidth = document.getElementById('ListingBrands').offsetWidth;
        if(contentWidth <= coverWidth)
        {
          var all = document.getElementsByClassName('brnadlist-slider-icon');
          for (var i = 0; i < all.length; i++) {
            all[i].className += " hide-content"; 
          }
        }
        // Main setion 
        var coverWidth = document.getElementById('link_brndListCoverMain').offsetWidth; 
        var contentWidth = document.getElementById('link_ListingBrands').offsetWidth;
        if(contentWidth <= coverWidth)
        {
          var all = document.getElementsByClassName('link-brnadlist-slider-icon');
          for (var i = 0; i < all.length; i++) {
            all[i].className += " hide-content"; 
          }
        }
      }, 500);
     
  }

  selectedDate(value: any) {
    this.selectedDateRangeLabel = value.label;   
    this.lsService.store('selecedDateLabel',this.selectedDateRangeLabel);
    this.datepickerOptions.startDate =  moment(value.start._d).format('YYYY-MM-DD');
    this.datepickerOptions.endDate = moment(value.end._d).format('YYYY-MM-DD');
    this.searchBase.fromDate = this.datepickerOptions.startDate ;
    this.searchBase.toDate = this.datepickerOptions.endDate;    
    this.getDashboardData();
  }
 
  regionSelected(region) {
    this.searchBase.region = region;
    this.getDashboardData();
  }
  linkTypeChanged(value) {
    this.searchBase.reportedLink = value;
    if(value == 'false'){
      this.reportedStatus = 'on';
      this.successStatus = 'on';
      this.disabledStatus = 'off';
      this.reported = false;
      this.disabled = true;
     
    }else{
      this.reportedStatus = 'off';
      this.successStatus = 'off';
      this.disabledStatus = 'on';
      this.reported = true;
      this.disabled = false;
      
    }
    
    this.getDashboardData();
  }

  getPercent(value: number, base: number) {
    if (base === 0) {
      return 0;
    } else {
      return Math.round(value * 100 / base);
    }
  }

  getDashboardData() {
    this.lsService. store('search_base', JSON.stringify(this.searchBase));
    this.loaderService.display(true);
    this.dashboardService.getDashboardData( this.searchBase )
      .subscribe(
        (res) => {
          if(res.json().status == "success"){            
            const data = res.json();
            
            this.successRateData = data.success_rate;

            this.mpData = {
              data: data.market_place.market_place,
              successRate: data.market_place.query_success_rate
            };
            if(this.mpData.data.length >= 1){
              this.mpArray = res.json().market_place.market_place;
            }else{
              this.mpArray = null;
            }      
            const mpvalues: any[] = this.mpData.data;
            if(mpvalues.length != 0){
              this.top1.market_place = mpvalues[0].id;
            }

            this.mpDatavalues = mpvalues.map(val => val.sum);

            this.dashboardService.mpDataChanged.next(this.mpData);

            this.smData = {
              data: data.social_media.social_media,
              successRate: data.social_media.query_success_rate
            };

            if(this.smData.data.length >= 1){
              this.smArray = this.smData.data;
            }else{
              this.smArray = null;
            } 

            const smvalues: any[] = this.smData.data;
            if(smvalues.length != 0){
              this.top1.social_media = smvalues[0].id;
            }

            this.smDatavalues = smvalues.map(val => val.sum);

            this.swData = {
              data: data.standalone_website.standalone_website,
              successRate: data.standalone_website.query_success_rate
            };
            if(this.swData.data.length >= 1){
              this.swArray = this.swData.data;
            }else{
              this.swArray = null;
            }   

            const swvalues: any[] = this.swData.data;
            if(swvalues.length != 0){
              this.top1.stand_alone = swvalues[0].id;
            }      
            this.lsService. store('top1', JSON.stringify(this.top1));  

            this.swDatavalues = swvalues.map(val => val.sum);


            const retrievedMapData: any[] = data.fake_product_per_region;

            if (retrievedMapData.length !== 0 ) {
              this.mapData = retrievedMapData.map( (val) => {val.reportedLink = this.searchBase.reportedLink.toString(); return val; } );
            } else {
              this.mapData = [];
            }

            this.reportedLinks.total = this.successRateData.total_reported_links.total_count;
            this.reportedLinks.sm = this.successRateData.total_reported_links.social_media;
            this.reportedLinks.mp = this.successRateData.total_reported_links.market_place;
            this.reportedLinks.sw = this.successRateData.total_reported_links.standalone;

            this.reportedLinks.percentageSm = this.getPercent( this.reportedLinks.sm, this.reportedLinks.total );
            this.reportedLinks.percentageMp = this.getPercent( this.reportedLinks.mp, this.reportedLinks.total );
            this.reportedLinks.percentageSw = this.getPercent( this.reportedLinks.sw, this.reportedLinks.total );

            this.disabledLinks.total = this.successRateData.total_disabled_links.total_count;
            this.disabledLinks.sm = this.successRateData.total_disabled_links.social_media;
            this.disabledLinks.mp = this.successRateData.total_disabled_links.market_place;
            this.disabledLinks.sw = this.successRateData.total_disabled_links.standalone;

            this.disabledLinks.percentageSm = this.getPercent( this.disabledLinks.sm, this.disabledLinks.total );
            this.disabledLinks.percentageMp = this.getPercent( this.disabledLinks.mp, this.disabledLinks.total );
            this.disabledLinks.percentageSw = this.getPercent( this.disabledLinks.sw, this.disabledLinks.total );

            this.successRate = this.getPercent(this.disabledLinks.total, this.reportedLinks.total);

          // this.drawGuage(successRate);

            // this.drawSmallGuage( this.mpSuccessRate, this.mpData.successRate );
            // this.drawSmallGuage( this.smSuccessRate, this.smData.successRate );
            // this.drawSmallGuage( this.swSuccessRate, this.swData.successRate );

            this.plotMap(this.regionMap, this.mapData);
            this.loaderService.display(false);
          }else{
            this.loaderService.display(false);
            if(res.json().message=="invalid_accesstoken"||res.json().message=="un_authorised"){
              //this.toastr.error(this.errorDscrp,this.errorMsg);
              this.authServiceObj.logout(true);
            }
          }
          
        }
      );
  }

  // drawGuage(successRate) {

  //   let d3ParentElement: D3.Selection< any, any, any, any>;

  //   if (this.parentNativeElement !== null) {

  //     d3ParentElement = D3.select(this.parentNativeElement); // <-- use the D3 select method

  //     const svg = d3ParentElement.select('svg');

  //     const g = svg.append('svg:g')
  //                  .attr('transform', 'translate(70, 110)');

  //     const arc = D3.arc();

  //     const path = arc({
  //         startAngle: 0,
  //         endAngle: Math.PI,
  //         innerRadius: 0,
  //         outerRadius: 75
  //       });

  //     const path2 = arc({
  //         startAngle: 0,
  //         endAngle: successRate * 0.01 * Math.PI,
  //         innerRadius: 65,
  //         outerRadius: 75
  //       });

  //     g.append('path')
  //     .attr('d', path)
  //     .attr('fill', '#DCDCDC')
  //     .attr('transform', 'rotate(-90)');
  //     g.append('path')
  //     .attr('d', path2)
  //     .attr('fill', '#7CA56C')
  //     .attr('class', 'guage-value')
  //     .attr('transform', 'rotate(-90)');

  //     const text = g.append('text')
  //                   .attr('text-anchor', 'middle')
  //                   .attr('x', 0)
  //                   .attr('y', -30)
  //                   .attr('font-size', 36)
  //                   .attr('fill', '#73879c');
  //     text.append('tspan')
  //       .attr('x', 0)
  //       .attr('font-size', 28)
  //       .attr('font-family', 'HelveticaNeue-Bold')
  //       .text('' + successRate + '%');
  //     text.append('tspan')
  //       .attr('x', 0)
  //       .attr('dy', 20)
  //       .attr('font-size', 12)
  //       .attr('font-weight', 'normal')
  //       .attr('font-family', 'HelveticaNeue')
  //       .text('Success Rate');
  //   }
  // }

  // drawSmallGuage( elRef: ElementRef, sRate: number ) {
  //   const el: HTMLElement = elRef.nativeElement;
  //   const successRate = sRate;

  //     const svg = D3.select(el)
  //                   .html('')
  //                   .append('svg')
  //                   .attr('width', 60)
  //                   .attr('height', 30)
  //                   .attr('viewBox', '-30 -30 60 30');

  //     const g = svg.append('svg:g');

  //     const arc = D3.arc();

  //     const path = arc({
  //         startAngle: 0,
  //         endAngle: Math.PI,
  //         innerRadius: 0,
  //         outerRadius: 30
  //       });

  //     const path2 = arc({
  //         startAngle: 0,
  //         endAngle: successRate * 0.01 * Math.PI,
  //         innerRadius: 25,
  //         outerRadius: 30
  //       });

  //     g.append('path')
  //     .attr('d', path)
  //     .attr('fill', '#DCDCDC')
  //     .attr('transform', 'rotate(-90)');
  //     g.append('path')
  //     .attr('d', path2)
  //     .attr('fill', '#7CA56C')
  //     .attr('class', 'guage-value')
  //     .attr('transform', 'rotate(-90)');
  //   }

  plotMap(elRef: ElementRef, mapData) {
    let el: HTMLElement;

    el = elRef.nativeElement;

    const width = el.clientWidth,

      height = el.clientHeight;

    const zoom = D3.zoom()
      .scaleExtent([1, 1.5])
      .translateExtent([[-100, -100], [width + 100, height + 100]])
      .on('zoom', zoomed);

    function zoomed() {
      svg.attr('transform', D3.event.transform);
    }

    const svg = D3.select(el).html('').append('svg')
      .attr('width', width)
      .attr('height', height-100)
      .call(zoom);

    const projection = D3.geoMercator()
      .scale(width / 2 / Math.PI)
      .translate([width / 2, height/1.6]);
    const path = D3.geoPath()
      .projection(projection);
    this.http.get('assets/world50.json')
      .subscribe(
        (res) => {

          const worldmap = res.json();

            const that = this;

            if (mapData === undefined) {
              mapData = [];
            }

            const data = mapData;

            function getColor(code) {

              if (that.mapData.length) {
                for (let i = 0; i < data.length; i++) {
                  if(i<5){

                    if (data[i].code === code) {
                      if (data[i].reportedLink === 'true') {
                        return '#b93030';
                      } else if (data[i].reportedLink === 'false') {
                        return '#7CA56C';
                      }
                    }

                  }else{

                    if (data[i].code === code) {
                      if (data[i].reportedLink === 'true') {
                        return '#ff8c1a';
                      } else if (data[i].reportedLink === 'false') {
                        return '#ff8c1a';
                      }
                    }

                  }
                    
                }

                return '#d4d4d4';
              } else {
                return '#d4d4d4';
              }
            }

            function getCountryData(code) {
              for (let i = 0; i < data.length; i++) {
                if (data[i].code === code) {
                  return data[i].source_counts.total;
                }
              }
              
            }
            var tooltip = D3.select("div.tooltip");
            svg.append('g')
              .attr('class', 'countries')
              .selectAll('path')
              .data(topojson.feature(worldmap, worldmap.objects.countries)['features'])
              .enter().append('path')
              .attr('stroke', 'rgba(170,170,170,0.4)')
              .attr('d', path)
              .attr('fill', (d) => {

                return getColor(d['properties'].iso_a2);
                } )
              .attr('id', function (d) { return d['id']; })
              // .append('title')
              // .text(function (d) {
              //   // tslint:disable-next-line:max-line-length
              //   return '' + d['properties'].name + ' : ' + (( getCountryData(d['properties'].iso_a2 )) ?  getCountryData(d['properties'].iso_a2 ) : '0');
              // });
              .on("mouseover",(d) =>{
                 return tooltip.style("hidden", false).html('<strong>' + d['properties'].name + ' : ' + (( getCountryData(d['properties'].iso_a2 )) ?  getCountryData(d['properties'].iso_a2 ) : '0')+'</strong>');
               })
               .on("mousemove",(d) =>{
                  this.top = this.regionMap.nativeElement.getBoundingClientRect().top;
                  this.left = this.regionMap.nativeElement.getBoundingClientRect().left;
                  if(D3.event.pageY < 1000){
                    this.top = 230;
                  }else{
                  this.top =  this.top + 1170;
                  this.left = this.left + 5;
                  }
                  // if (data.some((item) => item.code == d['properties'].iso_a2)) {
                      tooltip.classed("hidden", false)
                              .style("top", (D3.event.pageY - this.top ) + "px")
                              .style("left", (D3.event.pageX - this.left) + "px")
                              .html('<strong>' + d['properties'].name + ' : ' + (( getCountryData(d['properties'].iso_a2 )) ?  getCountryData(d['properties'].iso_a2 ) : '0')+'</strong>');
                  // }
              })
               
             .on("mouseout",function(d,i){
               tooltip.classed("hidden", true);
               });

        }
      );
      
  }

  customizePie( selection, pieData ) {

    selection.select('path')
              .attr('stroke-width', 2)
              .attr('stroke', '#fff');
    selection.append('title')
      .text((datum, index) => this.titleCasePipe.transform(pieData[index].name) + ' : ' + pieData[index].sum );
  }

  onMpChartUpdate(selection) {
    this.customizePie(selection, this.mpData.data);
  }

  onSmChartUpdate(selection) {
    this.customizePie(selection, this.smData.data);
  }

  onSwChartUpdate(selection) {
    this.customizePie(selection, this.swData.data);
  }

  

}
