import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { LocalStorageService } from 'ngx-webstorage';
import { BrandService } from '../../services/brand/brand.service';
import { AppSettings } from '../../settings.global';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { MessageServiceService } from '../../services/message-service/message-service.service';
import { LoaderService } from '../../services/loader/loader.service';
import { ToastrService } from 'ngx-toastr';
declare var $:any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  brands: {
    id: number;
    name: string;
  }[];
  userDetails: {
    id: number;
    email: string;
    first_name: string;
    last_name: string;
    logo: string;
    client_name: string;
    mobile_no: number;
    profile_pic: string;
    token: string;
    user_role: number;
  };
  userProfilePic = 'assets/images/profile_avatar_placeholder.png';
  clientLogoUrl: string;
  clientLogoAlt: string;
  clientSelected;
  toggleState = false;
  subscription: Subscription;
  public errorMsg = AppSettings.Session_Expire_Message;
  public errorDscrp = AppSettings.Session_Expire_Description;
  public clients: any;
  public client: any;
  public dropdownBrand: Array<any>;
  public selectedBrands: Array<any>;
  public dropdownSettings = {};
  public activeBrandId=[];
  

  constructor(private authService: AuthService,
    private lsService: LocalStorageService,
    private MsgService: MessageServiceService,
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private brandService: BrandService,
    private router: Router) {
    this.userDetails = lsService.retrieve('web_user_details');
    console.log(this.userDetails, "sssssssssss");
    if (this.userDetails.logo !== null) {
      this.clientLogoUrl = AppSettings.IMAGE_BASE_URL + this.userDetails.logo;
    }
    this.clientLogoAlt = this.userDetails.client_name;

    if (this.userDetails.profile_pic != null) {
      this.userProfilePic = AppSettings.IMAGE_BASE_URL + this.userDetails.profile_pic;
    }

    //message for logo change
    //this.loaderService.display(true);
    this.subscription = this.MsgService.getMessage().subscribe(message => {
      //this.loaderService.display(false);
      this.userDetails = lsService.retrieve('web_user_details');
      if (this.userDetails.logo !== null) {
        this.clientLogoUrl = AppSettings.IMAGE_BASE_URL + this.userDetails.logo;
      }
      this.clientLogoAlt = this.userDetails.client_name;

      if (this.userDetails.profile_pic != null) {
        this.userProfilePic = AppSettings.IMAGE_BASE_URL + this.userDetails.profile_pic;
      }
    });
  }

  ngOnInit() {

    this.dropdownBrand = [];
    this.selectedBrands = [];
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: 'All',
      unSelectAllText: 'All',
      itemsShowLimit: 1,
      allowSearchFilter: false,
      enableCheckAll:true
      //,minSelectionLimit:1
    };

    console.log("header");
    if (this.userDetails.user_role == 3 || this.userDetails.user_role == 1) {
      this.getClient();
    } else if (this.userDetails.user_role == 2) {
      this.getBrandDetails(null);
    }

  }

  //get the client 
  getClient() {

    this.brandService.getClientSelect().subscribe((res: any) => {
      if (res.status == 'success') {
        if (res.data_found) {
          this.clients = res.data_found;
          const lsClientId = this.lsService.retrieve('active_client_id');
          if (lsClientId != null) {
            this.client = lsClientId;
            this.lsService.store('active_client_id', this.client);
            if (this.userDetails.logo !== null) {
              this.clientLogoUrl = AppSettings.IMAGE_BASE_URL + this.userDetails.logo;
            }
            this.clientLogoAlt = this.userDetails.client_name;
          } else {
            this.client = this.clients[0].id;
            this.userDetails.logo = this.clients[0].logo_url;
            this.userDetails.client_name = this.clients[0].name;
            this.lsService.store('web_user_details', this.userDetails);
            this.lsService.store('active_client_id', this.client);

            if (this.userDetails.logo !== null) {
              this.clientLogoUrl = AppSettings.IMAGE_BASE_URL + this.userDetails.logo;
            }
            
            this.clientLogoAlt = this.userDetails.client_name;
          }
        } else {
          this.clients = null;
          this.client = null;
        }
        // if(this.activatedRoute.snapshot.queryParams['client_id']){
        // 	this.client=parseInt(this.activatedRoute.snapshot.queryParams['client_id'])

        // }else{
        // 	if(this.clients){
        // 		this.client=this.clients[0].id;	
        // 	}else{
        // 		this.client=null;
        // 	}

        // }
        if (this.client != null) {
          this.getBrandDetails(this.client);
        }

      }
      else {
        if (res.json().message == "invalid_accesstoken" || res.json().message == "un_authorised") {
          this.toastr.error("Kindly login", "Session Expired");
          this.authService.logout(false);
        }
      }
    })
  }

  getBrandDetails(client: any) {

    this.loaderService.display(true);
    this.brandService.getBrands(this.userDetails.id, client)
      .subscribe(
        (res) => {

          if (res.json().status == "success") {
            const data = res.json();

            if (data.status === 'success') {
              this.brands = data.data_found;
              var brandLength = this.brands.length;
              var j=0;
              this.dropdownBrand = [];
              this.activeBrandId = [];

              for(var i=1;i<=brandLength;i++){
                this.dropdownBrand.push(this.brands[j]);
                // this.selectedBrands.push(this.brands[j]);
                j++;
              }
              this.onSelectAll(this.dropdownBrand);
              const lsBrandId = this.lsService.retrieve('active_brand_Id');  
              var data;
              if (lsBrandId != null||[]) {
                this.lsService.clear('active_brand_Id');              
                this.lsService.store('active_brand_id', this.activeBrandId);
                //console.log(this.brands);
                console.log("Current active brand id is: ",this.lsService.retrieve('active_brand_Id'));
                // this.brandService.brandChanged.next(this.brandSelected);
              } else {
                this.lsService.clear('active_brand_Id');            
                this.lsService.store('active_brand_id',this.activeBrandId);
                // this.brandService.brandChanged.next(this.brandSelected);
              }

              this.onBrandSelect(this.activeBrandId);
              this.loaderService.display(false);
            }
            
          } else {
            this.loaderService.display(false);
            if (res.json().message == "invalid_accesstoken" || res.json().message == "un_authorised") {
              //this.toastr.error(this.errorDscrp,this.errorMsg);
              this.authService.logout(false);
            }
          }

        });
  }

  onBrandSelect(data) {
    this.brandService.brandChanged.next(data);
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };

    let currentUrl = this.router.url + '?';

    this.router.navigateByUrl(currentUrl)
      .then(() => {
        this.router.navigated = false;
        this.router.navigate([this.router.url]);
      });

  }

  onClientSelect(data) {
    //this.brandService.brandChanged.next(data);
    this.activeBrandId = [];
    this.selectedBrands = [];
    this.lsService.store('active_client_id', data);
    this.clients.forEach(element => {

      if(element.id==data){
        this.userDetails.logo = element.logo_url;
        this.userDetails.client_name = element.name;
      }
      
    });
    this.lsService.clear('active_brand_Id');
    this.getBrandDetails(data);
    this.lsService.store('web_user_details', this.userDetails);
    if (this.userDetails.logo !== null) {
      this.clientLogoUrl = AppSettings.IMAGE_BASE_URL + this.userDetails.logo;
    }
    this.clientLogoAlt = this.userDetails.client_name;
   //this.MsgService.sendMessage('Change Logo');

  }

//   $scope.selected = {};
// $scope.selectAll = function(){
//   for (var i = 0; i < $scope.items.length; i++) {
//     var item = $scope.items[i];

//     $scope.selected[item.id] = true;
//   }
// };

onItemSelect(item: any) {
  console.log("Xmen 1 entered: ");
  var index = this.activeBrandId.indexOf(item.id);
  console.log("The index is:-------------------",index);
  if (index!=0){
    this.activeBrandId.push(item.id);
    this.onBrandSelect(this.activeBrandId);
    this.lsService.store('active_brand_id', this.activeBrandId);
    console.log("The local storage data is:", this.lsService.retrieve('active_brand_Id'));

    // Section to store selected brand and id on local storage
    // Store brand data as json with array of objects
    // Added By : Arif
    var selectedBrandsToStore = [];
    var selectedBrands = this.lsService.retrieve('selectedBrands');
    //var selectedBrands = selectedBrands.push(item);
    console.log("selectedBrands to store1:------------------",selectedBrands);
    if(!selectedBrands || JSON.parse(selectedBrands) == []) selectedBrandsToStore = [];
    else selectedBrandsToStore =  JSON.parse(selectedBrands);

    if(item != null) 
    {selectedBrandsToStore.push(item);
        //var itemIndex = selectedBrandsToStore.findIndex(brandsSel => brandsSel.id === item.id);
      //var itemIndex = selectedBrandsToStore.indexOf(item.id);
      console.log("itemIndex is--------------------------",selectedBrandsToStore);
      //if(itemIndex == -1) selectedBrandsToStore.push(item); 
    }

    console.log("selectedBrandsToStore is:------------------", selectedBrandsToStore);
    var dataToStore = JSON.stringify(selectedBrandsToStore);
    this.lsService.store('selectedBrands', dataToStore);
  }
}

onItemDeSelect(item: any){
  
  var id = item.id;
  var index = this.activeBrandId.indexOf(id);
  console.log("The length of array is :",this.activeBrandId.length)
  
  if(this.activeBrandId.length!=1){
  this.activeBrandId.splice(index,1);
  this.lsService.store('active_brand_id', this.activeBrandId);
  this.onBrandSelect(this.activeBrandId);  
  console.log("The local storage data is: ", this.lsService.retrieve('active_brand_Id'));

  //  Section to remove unchecked brand from local storage
  // Added By Arif
  var selectedBrandsToStore = [];
          var selectedBrands = this.lsService.retrieve('selectedBrands');
          if(!selectedBrands || JSON.parse(selectedBrands) == []) selectedBrandsToStore = [];
          else selectedBrandsToStore =  JSON.parse(selectedBrands);  
          if(item != null) 
          {
            var itemIndex = selectedBrandsToStore.findIndex(brandsSel => brandsSel.id === item.id);
            selectedBrandsToStore.splice(itemIndex,1); 
          }
          var dataToStore = JSON.stringify(selectedBrandsToStore);
          this.lsService.store('selectedBrands', dataToStore);
  this.loaderService.display(false);

  }else{
        this.toastr.warning('','At least one brand must be selected');
        var delayInMilliseconds = 1000; //1 second
        this.loaderService.display(true);
        setTimeout(function() {
          //your code to be executed after 1 second
          this.selectedBrands = [];
          this.selectedBrands[0]= item;
          this.activeBrandId[0] = item.id;
          this.onBrandSelect(item.id);
          this.onItemSelect(item);

          var dataToString = JSON.stringify(item);
      this.lsService.store('selectedBrands',dataToString);      
      var selectedBrandsToStore = [];
      var selectedBrand = this.lsService.retrieve('selectedBrands');
      
      if(!selectedBrand || JSON.parse(selectedBrand) == []) selectedBrandsToStore = [];
      else selectedBrandsToStore =  JSON.parse(selectedBrand);   
      var dataToStore = []; 
          dataToStore[0]= selectedBrandsToStore;
      var stringdata =JSON.stringify(dataToStore)
      this.lsService.store('selectedBrands', stringdata);
          this.loaderService.display(false);
        }.bind(this),delayInMilliseconds);
  }
  
}

onSelectAll(item: any) {

  this.activeBrandId = [];
  this.selectedBrands = [];
  var allSelectedBrands = [];
  var length = item.length;
  for(var i=0;i<length;i++){
    this.selectedBrands.push(item[i]);
    this.activeBrandId[i] = this.selectedBrands[i].id;
    allSelectedBrands.push(item[i]);
  }//selectedBrands
  this.onBrandSelect(this.activeBrandId);
  this.lsService.store('active_brand_id', this.activeBrandId);
  this.lsService.store('selectedBrands',  JSON.stringify(allSelectedBrands));
  console.log("The local storage data is: ", this.lsService.retrieve('active_brand_Id'));
}

onDeSelectAll(item: any){
  this.toastr.warning('','At least one brand must be selected');
  this.loaderService.display(true);
  this.activeBrandId  = [];

  var delayInMilliseconds = 1000; //1 second
    setTimeout(function() {
      // your code to be executed after 1 second
      this.selectedBrands = [];
      this.selectedBrands[0] = this.dropdownBrand[0];
      this.activeBrandId[0] = this.dropdownBrand[0].id;
      this.lsService.store('active_brand_id', this.activeBrandId);
      this.onBrandSelect(this.dropdownBrand[0].id);
      this.onItemSelect(this.dropdownBrand[0]);
      
      // For Dashboard operations
      var dataToString = JSON.stringify(this.dropdownBrand[0]);
      this.lsService.store('selectedBrands',dataToString);      
      var selectedBrandsToStore = [];
      var selectedBrand = this.lsService.retrieve('selectedBrands');
      
      if(!selectedBrand || JSON.parse(selectedBrand) == []) selectedBrandsToStore = [];
      else selectedBrandsToStore =  JSON.parse(selectedBrand);   
      var dataToStore = []; 
          dataToStore[0]= selectedBrandsToStore;
      var stringdata =JSON.stringify(dataToStore)
      this.lsService.store('selectedBrands', stringdata);
      // if(this.dropdownBrand[0] != null) 
      // {
      //   // var itemIndex = selectedBrandsToStore.findIndex(brandsSel => brandsSel.id === this.dropdownBrand[0].id);
      //   // if(itemIndex == -1) 
      //   selectedBrandsToStore.push(this.dropdownBrand[0]); 
      // }

      
      // this.lsService.store('selectedBrands', dataToStore);
      //     var selectedBrandsToStore = [];
      //     var selectedBrands = this.lsService.retrieve('selectedBrands');
      //     if(!selectedBrands || JSON.parse(selectedBrands) == []) selectedBrandsToStore = [];
      //     else selectedBrandsToStore =  JSON.parse(selectedBrands);  
      //     if(this.dropdownBrand[0] != null) 
      //     {
      //       var itemIndex = selectedBrandsToStore.findIndex(brandsSel => brandsSel.id === this.dropdownBrand[0]);
      //       selectedBrandsToStore.splice(itemIndex,1); 
      //     }
      //     console.log("selectedBrandsToStore is:", selectedBrandsToStore);
      //     var dataToStore = JSON.stringify(selectedBrandsToStore);
      //     this.lsService.store('selectedBrands', dataToStore);
        this.loaderService.display(false);
      }.bind(this),delayInMilliseconds);
}

onLogout() {
  this.authService.logout(false);
}

}
